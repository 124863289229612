@import "src/styles/helpers";

.tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: var(--secondary-200);

  &__icon {
    width: 16px;
    height: 16px;
    color: var(--primary-600);

    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    @include bodySmall;
    color: var(--dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  &__closeButton {
    display: flex;
    &Icon {
      width: 12px;
      height: 12px;
      color: var(--grey);
    }
  }

  &_onlyIcon {
    padding: 0;
    border-radius: 0;
    background-color: unset;

    .tag__icon {
      width: 24px;
      height: 24px;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .tag__text {
      display: none;
    }

    .tag__closeButton {
      display: none;
    }
  }

}
