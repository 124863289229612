@import "src/styles/helpers";

.statusFilter {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &.active:before {
    background: var(--green);
  }

  &.expiredSoon:before {
    background: var(--yellow);
  }

  &.expired:before {
    background: var(--red);
  }

  &.prevVersion:before {
    background: var(--secondary-950);
  }

  &.draft:before {
    background: var(--secondary-950);
  }
}
