@import "src/styles/helpers";

.uploadSnackbar {
  position: fixed;
  z-index: 999;
  bottom: 32px;
  right: 32px;
  background: var(--white);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid var(--secondary-400);
  box-shadow: var(--box-shadow);

  @include media(tabletPortrait) {
    bottom: 16px;
    right: 16px;
  }

  @include media(mobile) {
    bottom: 16px;
    right: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: 40px 1fr auto;
    gap: 8px;
  }

  .icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    @include bodyMedium;
    color: var(--dark);
  }

  .subtitle {
    @include bodySmallLight;
    color: var(--grey);
    margin-top: 4px;
  }

  .progressBar {
    margin-top: 16px;
  }
}

.controls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: row-reverse;
  gap: 12px;
  margin-left: 24px;

  &_single {
    .controlButton {
      grid-column: 2 / 3;
    }
  }

  .controlButton {
    width: 20px;
    height: 20px;
    color: var(--primary-500);
    @include ease;

    &:hover {
      color: var(--accent-900);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_drawer {
    .controlButton {
      width: 24px;
      height: 24px;
    }
  }
}

.progressBar {
  border-radius: 2px !important;
  background: var(--primary-100) !important;

  :global {

    .MuiLinearProgress-bar {
      border-radius: 2px !important;
      background: var(--primary-400) !important;
    }
  }
}

.files {
  display: grid;
  grid-auto-rows: min-content;
  gap: 24px;
}

.fileItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-400);
    padding-bottom: 24px;
  }

  &__content {
    height: min-content;
  }

  &__indicator {
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }

    .loadingIcon {
      animation: spin 1s linear infinite;

      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__text {
    display: grid;
    align-items: center;
    gap: 4px;
  }

  &__error {
    color: var(--red);
    @include bodySmallLight;
    margin-top: 4px;
  }

  &__parsingStatus {
    color: var(--grey);
    @include bodySmallLight;
    margin-top: 4px;

    &.error {
      color: var(--red-300)
    }
  }

  &__completedLink {
    margin-top: 4px;
  }

  &__title {
    @include bodyMedium;
    color: var(--dark);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 336px;
  }

  &__progress {
    color: var(--grey);
    @include bodySmallLight;
  }

  .progressBar {
    margin-top: 12px;
  }

  &__delete {
    width: 20px;
    height: 20px;
    color: var(--primary-500);
    @include ease;

    &:hover {
      color: var(--red);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.drawer {
  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    button {
      width: 100%;
    }
  }
}

.limitModalButtons {

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

.filesTitle {
  @include h3;
  color: var(--dark);
  margin-bottom: 12px;
  position: sticky;
  top: 0;
}

.uploadedFiles {
  margin-bottom: 32px;
}

.filesUploadDrawer {
  margin-top: 0 !important;

  &__content {
    display: grid;
    height: 100%;

    &Files {
      display: grid;
      gap: 40px;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
