@import "src/styles/helpers";

.buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 12px;
  margin-left: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.langSelect {
  width: 70px;
}

.selectedLanguage {
  width: 70px;
  border: 1px solid var(--secondary-500);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  [aria-describedby="simple-popover"] & {
    border-radius: 8px 8px 0 0;
    border-bottom-color: var(--secondary-400);

    .currentLanguage__arrow {
      transform: rotate(180deg);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__arrow {
    width: 20px;
    height: 20px;
    transition: transform .3s ease;
  }

  &:hover {
    color: var(--accent-700);
    border-color: var(--accent-700);
  }

  &__item {
    width: 70px;
    padding: 11px;
    color: var(--primary-500);
    display: flex;
    align-items: center;
    gap: 8px;
    @include bodySmall;
    @include ease;
    background: var(--white);
    max-height: 42px;

    &__flag {
      width: 18px;
      height: 18px;
    }

    &:not(.selectedLanguage) {

      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        color: var(--accent-700);
        background: var(--secondary-100);
      }
    }
  }
}
