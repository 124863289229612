@import "src/styles/helpers";

.input {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 12px;
  padding: 20px 18px;
  border-radius: 8px;
  border: 1px dashed var(--secondary-600);
  cursor: pointer;
  height: 116px;
  @include ease;
  transition-property: background, border-color;
  user-select: none;

  &:hover {
    background: var(--primary-50);
    border-color: var(--primary-500);

    .input__actions {
      opacity: 1;
    }

    .input__preview {
      filter: brightness(70%);
    }
  }

  &__main {
    position: relative;
    height: 114px;
  }

  &__actions {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    gap: 15px;
    opacity: 0;
    z-index: 1;
    @include ease;
  }

  &__action {
    width: 18px;
    height: 18px;
    color: var(--primary-50);

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &_preview {
    padding: 0;
    border: 1px dashed var(--primary-500);
    cursor: default;
  }

  &__preview {
    height: 114px;
    max-width: 100%;
    filter: none;
    @include ease;
  }

  &__icon {
    width: 48px;
    height: 48px;
    color: var(--primary-500);

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  &__label {
    @include bodySmall;
    color: var(--primary-600);

    &Optional {
      @include bodySmallLight;
      color: var(--grey);
    }

    &_preview {
      display: none;
    }
  }
}

.error {
  color: var(--red);
  margin-top: 4px;
  @include bodySmall
}
