@import "src/styles/helpers";

.header {
  &__main {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 24px;
    padding: 16px 32px;
    border-bottom: 1px solid var(--secondary-400);

    @include media(tabletPortrait) {
      padding: 16px;
    }

    @include media(mobile) {
      padding: 16px;
    }
  }
}

.mobileHeader {
  margin-bottom: 24px;

  &__title {
    @include h4;
    color: var(--primary-600);
  }

  @include media(mobile) {
    margin-bottom: 16px;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.title {
  @include h2;
  color: var(--primary-600);

  @include media(tablet) {
    @include h3;
  }

  @include media(mobile) {
    display: none;
  }
}

// TODO: Fix this behavior in button
.button {
  max-width: 42px;
  max-height: 42px;

  &.newNotifications {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: var(--red);
      border: 1px solid var(--white);
      border-radius: 50%;
      top: 10px;
      right: 10px;
    }
  }
}

.titleAndMobileMenuButton {
  display: flex;
  align-items: center;
  gap: 16px;
}

.titleAndBackLink {
  display: flex;
  align-items: center;
  gap: 8px;
}

.backLink {
  color: var(--primary-500);
  width: 24px;
  height: 24px;
  padding: 0 !important;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
