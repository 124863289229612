@import "src/styles/helpers";

.angleIcon {
  width: 20px;
  height: 20px;
  color: var(--primary-500);
  @include ease;
}

.title {
  margin-right: 16px;
  @include h4;
  @include ease;
  color: var(--dark);
}

.accordion {
  box-shadow: none !important;

  &:before {
    display: none !important;
  }

  &:not(:last-child) {
    margin-bottom: 8px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--secondary-400) !important;
  }

  .summary {
    background: var(--white) !important;
    padding: 16px 0 !important;
    min-height: auto !important;

    &:hover {
      .title, svg {
        color: var(--accent-900);
      }
    }

    &_expanded {
      &:hover {
        cursor: default !important;
        .title, svg {
          color: var(--dark);
        }
      }
    }

    :global {

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
    }
  }

  .details {
    background: var(--white) !important;
    padding: 0 0 16px !important;
    margin-top: 4px !important;
  }
}
