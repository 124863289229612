@import "src/styles/helpers";

.userAvatar {
  height: 42px;
  width: 42px;

  &__image {
    @include buttonFont;
    @include ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-500);
    background: var(--primary-50);
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__initials {
    @include buttonFont;
    @include ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-500);
    padding: 2px;
    background: var(--primary-50);
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    white-space: nowrap;
  }
}
