@import "src/styles/helpers";

.wrapper {
  display: grid;
  gap: 24px;

  @include media(mobile) {
    gap: 16px;
  }
}

.main {
  border-radius: 8px;
  border: 1px solid var(--secondary-400);

  &_loading {
    opacity: 0.5;
    cursor: progress;

    > * {
      pointer-events: none;
    }
  }
}

.footer {
  border-top: 1px solid var(--secondary-400);
  border-radius: 0 0 8px 8px;
}

.loader {
  display: grid;
  align-items: center;
  justify-content: center;
}
