@import "src/styles/helpers";

.tabs {
  :global {
    .MuiTabs-root {
      border-bottom: 1px solid var(--secondary-400) !important;
      min-height: min-content !important;

      .MuiTabs-flexContainer {
        display: flex;
        gap: 24px;
      }
    }

    .MuiTabs-indicator {
      height: 2px !important;
      background-color: var(--accent-900) !important;
    }

    .MuiTab-root {
      @include bodyMediumLight(true);
      font-family: Inter, sans-serif !important;
      letter-spacing: normal !important;
      text-transform: capitalize !important;
      color: var(--primary-600) !important;
      white-space: nowrap;
      padding: 0px 8px 12px 8px;
      max-width: min-content !important;
      min-width: min-content !important;
      min-height: min-content !important;
      transition: all .3s ease;

      &:hover, &:focus {
        color: var(--accent-700) !important;
      }

      &.Mui-selected {
        @include bodyMedium(true);
      }
    }
  }
}

.fullWidth {
  :global {
    .MuiTabs-flexContainer {
      display: flex;
      justify-content: space-between;

      .MuiButtonBase-root {
        flex: 1;
        max-width: 100% !important;
      }
    }
  }
}
