@import "src/styles/helpers";

.action {
  @include ease;
  position: relative;
  width: 18px;
  height: 18px;
  max-width: 18px;
  border-radius: 50%;
  background: var(--accent-800);

  &:hover {
    background: var(--accent-500);
  }

  &:active {
    background: var(--accent-900);
  }

  &:disabled,
  &.disabled {
    background: var(--accent-800);
    cursor: default;

    &:hover {
      background: var(--accent-800);
    }
  
    &:active {
      background: var(--accent-800);
    }
  }

  &__icon {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    display: flex;
    width: 12px;
    height: 12px;
    color: var(--white);

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.tooltip {
  background: var(--dark) !important;
  padding: 4px 8px !important;
  @include bodySmallLight(true);

  &__arrow:before {
    color: var(--dark) !important;
  }
}
