@import "src/styles/helpers";

.notFoundPage {
  display: grid;
  align-content: center;
  justify-items: center;
  gap: 24px;
  height: 100vh;
  
  &__image {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  &__content {
    display: grid;
    justify-items: center;
    gap: 13px;
    text-align: center;
  }

  &__title {
    @include h1;
    color: var(--primary-400);
  }

  &__info {
    @include bodyMediumLight;
    color: var(--primary-600);
  }
}
