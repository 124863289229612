@import 'src/styles/helpers';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include media(mobile) {
    flex-direction: column;
    align-items: normal;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__title {
    display: none;

    @include media(mobile) {
      @include h4;
      display: block;
    }
  }

  &__total {
    @include h4;
    color: var(--primary-600);

    @include media(mobile) {
      @include subtitleSmall;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    & > button {
      width: 100%;
    }
  }
}

.table {
  height: 100%;

  &__cellText {
    @include bodySmall(true);
    color: var(--dark);
    width: 100%;
  }

  &__cellLink {
    @include linkTextSmall;
    color: var(--primary-500);
  }

  &__cellEmpty {
    @include bodySmall;
    color: var(--grey);
    text-align: center;
    width: 100%;
  }

  &__cellQuantity {
    @include bodySmall(true);
    color: var(--dark);
    width: 100%;
    text-align: center;
  }
}
