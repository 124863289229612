@import "src/styles/helpers";

.chip {
  @include bodySmall;
  display: grid;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 25px;
  color: var(--primary-500);
  white-space: nowrap;
  width: min-content;

  &_primary {
    background-color: var(--primary-50);
  }

  &_info {
    background-color: var(--secondary-300);
  }

  &_success {
    color: var(--green);
    background: var(--accent-50);
  }

  &_warning {
    color: var(--yellow);
    background: var(--light-yellow);
  }

  &_danger {
    color: var(--red);
    background: var(--light-red);
  }

  &_disabled {
    color: var(--secondary-950);
    background-color: var(--secondary-100);
  }

  &_accent {
    background-color: var(--accent-50);
  }

  &_lightPurple {
    background-color: var(--light-purple);
  }

  &_lightYellow {
    background-color: var(--light-yellow);
  }

  &_primaryDark {
    background-color: var(--primary-100);
  }

  &_large {
    @include bodyMedium;
  }

  &_small {
    @include bodySmall;
  }
}
