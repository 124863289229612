@import "src/styles/helpers";

.deleteButton.button {
  background: var(--red);

  &:hover {
    background: var(--red-200);
  }

  &:active, &:focus {
    background: var(--red-400);
  }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}
