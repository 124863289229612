@import "src/styles/helpers";

.label {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  padding: 4px 0;
  font-family: Inter, sans-serif;

  &__value {
    @include bodyMediumLight;
    text-transform: none;
    font-family: Inter, sans-serif;
  }

  &__quantity {
    color: var(--grey);
  }
}

.checkboxContent {
  padding: 4px 0;
  display: grid;
  gap: 4px;
  max-height: 400px;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-200);
    border-radius: 3.5px;
  }
}

.content {
  display: grid;
  grid-template-rows: 1fr auto;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  button {
    width: 100%;
  }
}

.accordion_wrapper {
  display: grid;
  gap: 16px;
}

.dateRangePicker {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 4px;
}

.datepicker {
  box-shadow: var(--box-shadow) !important;
  z-index: 1200 !important;

  :global {
    .rs-calendar:first-child {
      border-right: none !important;
    }

    .rs-btn-primary {
      height: 32px !important;
      padding: 0 12px !important;
      border-radius: 8px !important;
      @include bodyExtraSmall(true);
    }

    .rs-calendar-table-cell-selected {
      .rs-calendar-table-cell-day {
        @include subtitle(true);
      }
    }

    .rs-calendar-table-cell-content {
      @include bodyMediumLight(true);
    }

    .rs-calendar-table-header-cell-content {
      @include subtitleSmall(true);
      color: var(--grey) !important;
    }

    .rs-btn-subtle:hover, .rs-btn-subtle:focus {
      background: var(--primary-100) !important;
    }

    .rs-calendar-header-title {
      @include h4(true);
      color: var(--dark) !important;

      &:hover, &:focus {
        background: transparent !important;
        color: var(--accent-900) !important;
      }
    }

    .rs-calendar-header {
      padding-bottom: 10px !important;
      border-bottom: 1px solid var(--secondary-600) !important;
    }

    .rs-picker-toolbar {
      border-top-color: var(--secondary-600) !important;
    }

    .rs-btn-icon.rs-btn-xs {
      width: 28px !important;
      height: 28px !important;
      color: var(--dark) !important;
    }

    .rs-calendar-body {
      padding-bottom: 0 !important;
    }
  }

  &__input {
    max-width: 192px !important;
    outline-color: var(--accent-900) !important;

    :global {

      .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within {
        outline: 1px solid var(--accent-900) !important;
      }
    }

    input {
      font-family: Inter, sans-serif;
      @include bodyMediumLight(true);
    }

    .calendarIcon {
      width: 16px;
      height: 16px;
    }
  }
}

.drawer {
  width: 448px;
}
