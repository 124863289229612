@import "src/styles/helpers";

.header {
  display: grid;
  grid-template-columns: minmax(min-content, 560px) 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &_mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 12px;

    & .actions {
      @include media(mobile) {
        gap: 12px;
      }
    }

    & .actions__right > button {
      width: 100%;
    }
  }
}

.searchWithBarcode {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  max-width: 560px;
  width: 100%;
  border-radius: 8px;

  @include media(mobile) {
    max-width: none;
  }

  &__types {
    display: grid !important;
    min-width: 120px !important;
    border-radius: 8px !important;
  }

  &__type {
    padding: 12px 16px;
    background: var(--secondary-50);
    @include bodySmall;
    color: var(--primary-600);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: var(--secondary-200);
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--secondary-400);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__input {
    height: 42px;
    padding: 4px 16px 4px 40px;
    border: 1px solid var(--secondary-500);
    border-radius: 0 8px 8px 0;
    @include bodyMedium;
    color: var(--primary-500);
    width: 100%;
    @include ease;

    &:focus, &:active {
      outline: none;
      border-color: var(--primary-400);

      &::placeholder {
        color: var(--primary-500);
      }

      ~ svg {
        color: var(--primary-500);
      }
    }

    &::placeholder {
      font-family: Inter, sans-serif;
      color: var(--grey);
      @include bodySmall;
    }

    &:not(:placeholder-shown) {

      ~ svg {
        color: var(--primary-500);
      }
    }
  }

  &__inputWrap {
    position: relative;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
      left: 16px;
      color: var(--grey);
    }

    .search__input:focus::placeholder {
      opacity: 0;
    }

    &:hover {

      .search__input {
        border-color: var(--primary-400);

        &::placeholder {
          color: var(--primary-500);
        }

        ~ svg {
          color: var(--primary-500);
        }
      }
    }
  }
}

.search__activeType {
  height: 42px;
  border: 1px solid var(--secondary-500);
  border-right: none;
  border-radius: 8px 0 0 8px !important;

  svg {
    width: 18px;
    height: 18px;
    color: var(--primary-500);
    transition: transform .3s ease;
  }

  &:hover svg {
    color: inherit;
  }

  [aria-describedby="simple-popover"] & {

    svg {
      transform: rotate(-180deg);
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
  }

  @include media(mobile) {
    gap: 8px;
  }
}

.filtersCounter {
  width: 18px;
  height: 18px;
  background: var(--dark);
  border-radius: 50%;
  @include bodySmall;
  color: var(--primary-50);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
