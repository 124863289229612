.drawer {
  .form {
    height: 100%;

    &__fields {
      display: grid;
      grid-auto-rows: min-content;
      gap: 24px;
    }

    &__actions {
      display: flex;
      gap: 16px;
    }

    &__button {
      flex: 1 0 auto;
    }
  }
}

.companyNameIcon {
  color: var(--grey) !important;
  right: 18px !important;
  width: 16px;
  height: 16px;
  transform: none !important;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
