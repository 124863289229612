@import "src/styles/helpers";

.radioGroup {
  width: 100%;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 14px 16px;

    &Label {
      margin: 0 !important;
      gap: 8px;

      :global {
        .MuiFormControlLabel-label {
          font-family: Inter, sans-serif;
          @include bodySmall;
          color: var(--dark);
        }
      }
    }

    &Radio {
      color: var(--accent-900) !important;
      padding: 0 !important;
  
      &Icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
