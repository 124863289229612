@import "src/styles/helpers";

.drawer {
  .form {
    height: 100%;

    &__fields {
      display: grid;
      grid-auto-rows: min-content;
      gap: 24px;
    }

    &__actions {
      display: flex;
      gap: 16px;
    }

    &__button {
      flex: 1 0 auto;
    }
  }
}

.warehousesBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__content {
    display: grid;
    gap: 12px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 8px;
  }

  &__button {
    padding: 0;
  }
}

.loader {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.languageField {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__note {
    @include subtitleSmall;
    color: var(--accent-900);
    max-width: 380px;
  }
}
