@import "src/styles/helpers";

.pdfLinkSelect {
  &__triggerButton {
    &_disabled button {
      cursor: auto !important;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    min-width: 56px;
    height: 28px;
    border-radius: 4px;
    background: var(--secondary-200);
    padding: 2px 8px;
    @include ease;
    @include bodySmall;
    color: var(--dark);

    &:hover {
      background: var(--secondary-400);
    }
  }

  &__content {
    margin-right: -12px !important;
    max-height: 300px !important;
    overflow-y: auto !important;
  }

  &__item {
    padding: 12px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--secondary-400);
    }

    &Content {
      display: flex;
      gap: 4px;

      &Trigger {
        display: flex;
        gap: 4px;
        width: 100%;
        padding: 12px 0;
      }
    }
  }

  &__arrow {
    width: 20px;
    height: 20px;
    transition: transform .3s ease;
    color: var(--grey);
  }
}

.action {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}
