@import "src/styles/helpers";

.tooltip {
  background: var(--dark) !important;
  padding: 4px 8px !important;
  @include bodySmallLight(true);

  &__arrow:before {
    color: var(--dark) !important;
  }
}
