@import 'src/styles/helpers';

.tableAction {
  background: var(--secondary-50) !important;
  padding: 12px 16px !important;
  @include bodySmall(true);
  font-family: Inter, sans-serif !important;

  &_delete {
    color: var(--red) !important;
  }

  &:first-child {
    border-radius: 8px 8px 0 0 !important;
  }

  &:last-child {
    border-radius: 0 0 8px 8px !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-400) !important;
  }

  &:hover {
    background: var(--secondary-200) !important;
  }

  :global {
    .MuiListItemIcon-root {
      min-width: 0 !important;
      margin-right: 8px;
      color: inherit !important;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
