.paper {
  background: var(--white) !important;
  box-shadow: var(--box-shadow) !important;
  overflow: unset !important;

  &.left {
    margin-left: -4px !important;
  }

  &.right {
    margin-left: 4px !important;
  }

  &.top {
    margin-top: -4px !important;
  }

  &.bottom {
    margin-top: 4px !important;
  }

  &.bottomRight {
    margin-top: 4px !important;

    &.select {
      border-radius: 0 0 8px 8px !important;
    }
  }

  &.bottomLeft {
    margin-top: 4px !important;
  }

  &.select {
    margin-top: 0 !important;
  }

  &.selectTop {
    margin-bottom: 0 !important;
  }
}

.button {
  display: block;
  color: var(--grey);

  &.active {
    color: var(--primary-600);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.triggerButton__wrapper {
  position: relative;
}
