@import 'src/styles/helpers';

.header {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 24px;

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(mobile) {
      flex-direction: column;
      align-items: normal;
      gap: 24px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    color: var(--primary-600);

    &Main {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 8px;
    }

    &Text {
      @include h3;

      @include media(mobile) {
        @include h4;
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 12px;

    @include media(mobile) {
      grid-template-columns: 1fr 1fr;

      a {
        width: 100%;
      }
    }
  }
}

.backLink {
  color: var(--primary-500);
  width: 24px;
  height: 24px;
  padding: 0 !important;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
