@import 'src/styles/helpers';

.table {
  height: 100%;

  &__user {
    display: grid;
    gap: 4px;

    &Name {
      @include bodySmall(true);
      color: var(--dark)
    }

    &Email {
      @include bodySmallLight(true);
      color: var(--primary-400)
    }
  }

  &__cellText {
    @include bodySmall(true);
    color: var(--dark);
    width: 100%;
  }

  &__cellLink {
    @include linkTextSmall;
    color: var(--primary-500);
  }

  &__cellEmpty {
    @include bodySmallLight;
    color: var(--grey);
  }
}

.dateTimeCell {
  display: flex;
  align-items: center;
  gap: 12px;

  &__time {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--primary-900);

    svg,
    img {
      width: 12px;
      height: 12px;
    }
  }
}
