@import 'src/styles/helpers';

.timeIcon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-left: 12px;
}

.time {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.email {
  @include bodySmallLight;
  color: var(--primary-400);
  margin-top: 4px;
}

.main {
  :global {
    .MuiDataGrid-row {

      &:local {
        &.hidden {
          display: none !important;
        }
      }
    }

    .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: flex-end !important;
    }
  }
}

.toggleButton {
  color: var(--secondary-800);

  &:hover {
    color: var(--dark);
  }

  svg {
    width: 16px;
    height: 16px;
    @include ease;
  }

  &.opened {
    color: var(--dark);

    svg {
      transform: rotate(180deg);
    }
  }
}
