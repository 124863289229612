@import "src/styles/helpers";

.bar {
  @include bodySmall;
  color: var(--primary-500);
  white-space: nowrap;
  border-radius: 8px;
  background-color: var(--secondary-200);
  padding: 12px 18px;
}
