@import "src/styles/helpers";

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.link {
  @include subtitle;
  color: var(--primary-600);
  text-decoration-line: underline;
  word-break: break-word;
}
