@import 'src/styles/helpers';

.notification {
  padding: 24px;
  border-bottom: 1px solid var(--secondary-400);
  display: grid;
  grid-template-columns: 42px auto;
  gap: 12px;

  &.new {
    position: relative;
    background: var(--secondary-100);

    &:after {
      content: '';
      position: absolute;
      right: 16px;
      top: 16px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: var(--green);
    }
  }
}

.icon {
  width: 42px;
  height: 42px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.content {

}

.name {
  @include subtitle;
  color: var(--dark);
  word-break: break-word;
}

.error {
  @include bodyMediumLight;
  color: var(--red);
}

.text {
  @include bodyMediumLight;
  color: var(--grey);
}

.time {
  margin-top: 4px;
  @include bodySmallLight;
  color: var(--grey);
}

.footer {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}
