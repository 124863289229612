@import "src/styles/helpers";

.controls {
  gap: 12px;
  display: flex;
  align-items: stretch;

  @include media(mobile) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    > button, label div {
      width: 100%;
    }
  }

  label {
    cursor: pointer;
  }
}

.header {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  gap: 32px;
  margin-bottom: 24px;

  @include media(mobile) {
    grid-template-columns: 1fr;
  }
}

.tabs {
  width: fit-content;

  &__wrap {
    max-width: 100%;
    overflow-x: auto;
  }
}

