@import "src/styles/helpers";

.paper {
  border-radius: 8px !important;
  box-shadow: 0px 9px 40px 0px rgba(116, 116, 116, 0.16) !important;
  
  :global {
    .MuiList-root {
      padding: 0 !important;
    }
  }

  &.left {
    margin-left: -4px !important;
  }

  &.right {
    margin-left: 4px !important;
  }

  &.top {
    margin-top: -4px !important;
  }

  &.bottom {
    margin-top: 4px !important;
  }

  &.bottomRight {
    margin-top: 4px !important;

    &.select {
      border-radius: 0 0 8px 8px !important;
    }
  }

  &.bottomLeft {
    margin-top: 4px !important;
  }

  &.select {
    margin-top: 0 !important;
  }

  &.selectTop {
    margin-bottom: 0 !important;
  }
}

.button {
  display: flex;
  color: var(--grey);

  svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: var(--primary-600);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.menu {
  &__item {
    @include bodySmall(true);
    font-family: Inter, sans-serif !important;
    display: flex;
    gap: 8px;
    padding: 14px 16px !important;
    min-width: 120px;
    max-width: 200px;

    svg {
      width: 16px;
      height: 16px;
    }

    &.primary {
      color: var(--dark) !important;
    }

    &.danger {
      color: var(--red-300) !important;
    }
  }
}
