@import "src/styles/helpers";

.upgradePlan {
  @include subtitleSmall;
  color: var(--accent-900);

  &__button {
    @include linkTextSmall;
    font-weight: 700;
    color: inherit;
  }
}
