@import "src/styles/helpers";

.table {
  height: 100%;

  :global {

    .MuiDataGrid-row {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: var(--secondary-500);
      }

      &:hover {
        background: var(--secondary-100) !important;
      }
    }
  }

  &__cellText {
    @include bodySmall;
    width: 100%;
  }

  &__cellEmpty {
    @include bodySmall;
    color: var(--grey);
    text-align: center;
    width: 100%;
  }

  :global {
    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
}

.addSDSButtonWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sdsLink {
  @include linkTextSmall;
  color: var(--primary-500);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &_new:before {
    background: var(--primary-200);
  }

  &_inProgress:before {
    background: var(--accent-400);
  }

  &_resolved:before {
    background: var(--primary-500);
  }
}
