@import "src/styles/helpers";

.wrapper {
  position: relative;
  width: 100%;
}

.pdfFile {
  padding: 4px 8px;
  @include bodySmall;
  color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  min-width: 100px;
  height: 28px;
  background: var(--secondary-200);
  border-radius: 4px;
  @include ease;

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 62px;

    @include media(desktopSmall) {
      max-width: 52px;
    }

    @include media(tablet) {
      max-width: 52px;
    }

    @include media(mobile) {
      max-width: 52px;
    }
  }

  &:hover {
    background: var(--secondary-400);
  }

  &__file {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    &.success {
      background: var(--green);
    }

    &.warning {
      background: var(--yellow);
    }

    &.danger {
      background: var(--red);
    }

    &.disabled {
      background: var(--secondary-950);
    }
  }
}

.action {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}
