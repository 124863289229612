@import "src/styles/helpers";

.select {
  position: relative;

  :global {
    .MuiInputLabel-root {
      color: var(--grey) !important;
      @include bodyMediumLight($important: true);
      font-family: Inter, sans-serif !important;
      letter-spacing: normal !important;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      padding: 0 !important;

      &.MuiInputLabel-shrink {
        @include bodySmall($important: true);
        transform: translate(14px, -8px) scale(1) !important;
        color: var(--dark) !important;

        .MuiInputLabel-asterisk {
          color: var(--red) !important;
        }
      }

      &.Mui-disabled {
        color: var(--grey) !important;

        &:hover {
          color: var(--grey) !important;
        }

        &.MuiFormLabel-filled {
          color: var(--dark) !important;

          &:hover {
            color: var(--dark) !important;
          }
        }
      }

      &.Mui-focused {
        color: var(--dark) !important;
      }

      &:not(.MuiInputLabel-shrink) {
        left: 3px !important;
        padding-top: 2px;
      }

      &.Mui-error {
        color: var(--grey) !important;

        &.MuiInputLabel-shrink {
          color: var(--dark) !important;
        }
      }
    }

    .MuiInputBase-root {
      display: inline-flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 8px;
      padding: 14px 30px 14px 18px !important;
      color: var(--dark) !important;

      .MuiInputBase-input {
        width: 0 !important;
        min-width: 50px !important;
        padding: 0 !important;
        flex-grow: 1 !important;

        &.Mui-disabled {
          color: var(--grey) !important;
          -webkit-text-fill-color: inherit !important;
        }
      }

      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;

        legend {
          font-family: Inter, sans-serif !important;
          @include bodySmall($important: true);
          letter-spacing: normal !important;
        }
      }

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) {

        &:hover {

          fieldset {
            border-color: var(--primary-400) !important;
          }
        }
      }

      &.Mui-disabled {
        background: var(--secondary-100);
        fieldset {
          border-color: var(--secondary-500) !important;
        }

        &:hover {
          fieldset {
            border-color: var(--secondary-500) !important;
          }
        }
      }

      &:focus-within,
      &:focus-visible {

        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }

      &.Mui-error {

        fieldset {
          border-color: var(--red) !important;
        }
      }
    }
  }

  .renderedValue {
    display: flex;
  }
}

.paper {
  background: var(--white);
  box-shadow: var(--box-shadow);
  z-index: 1200;
  overflow: hidden;
  border-radius: 8px;

  &__list {
    max-height: 275px;
  }

  &__footer {
    padding: 16px;
    border-top: 1px solid var(--secondary-400);
  }
}

.list {
  @include scrollbar;
  overflow-y: auto;
  padding: 0 !important;

  :global {
    .MuiListItem-root {
      color: var(--dark) !important;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      cursor: pointer;

      &.underline:not(:last-child) {
        border-bottom: 1px solid var(--secondary-400);
      }

      &:hover {
        background: var(--secondary-200) !important;
      }
    }
  }

  &__item {
    &Title {
      @include bodySmall;
      font-family: Inter, sans-serif !important;
      pointer-events: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &Icon {
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  
    &SelectedMark {
      color: var(--green);
      width: 16px;
      height: 16px;
    }

    &_noOptions {
      justify-content: center !important;

      .list__itemTitle {
        @include bodySmall;
        color: var(--grey);
      }
    }
  }
}

.group {
  &__header {
    @include subtitleSmall;
    color: var(--grey);
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Info {
      @include bodySmall;
      color: var(--accent-900);
    }
  }
}

.disableRotation {
  transform: none !important;
}

.error {
  color: var(--red);
  margin-top: 4px;
  @include bodySmall
}

.inputIcon {
  position: absolute;
  color: var(--grey) !important;
  width: 16px;
  height: 16px;
  right: 16px;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}

.hiddenTagCount {
  @include bodySmall;
  color: var(--dark);
}

.loader {
  div {
    width: 16px !important;
    height: 16px !important;
  }
}
