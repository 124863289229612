@import "src/styles/helpers";

.languageItem {
  padding: 11px;
  color: var(--primary-500);
  display: flex;
  align-items: center;
  gap: 8px;
  @include bodySmall;
  @include ease;
  width: 100%;
  background: var(--white);
  max-height: 42px;

  &__flag {
    width: 18px;
    height: 18px;
  }

  &:not(.currentLanguage) {

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      color: var(--accent-700);
      background: var(--secondary-100);
    }
  }
}

$selectWidth: 104px;
.currentLanguage {
  border: 1px solid var(--secondary-500);
  border-radius: 8px;
  width: $selectWidth;
  justify-content: space-between;

  [aria-describedby="simple-popover"] & {
    border-radius: 8px 8px 0 0;
    border-bottom-color: var(--secondary-400);

    .currentLanguage__arrow {
      transform: rotate(180deg);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__arrow {
    width: 20px;
    height: 20px;
    transition: transform .3s ease;
  }

  &:hover {
    color: var(--accent-700);
    border-color: var(--accent-700);
  }
}

.content {
  width: $selectWidth;
  border-radius: 0 0 8px 8px !important;
  border: 1px solid var(--secondary-500) !important;
  border-top: none !important;
  box-shadow: 0 37px 36.9px 0 rgba(160, 160, 160, 0.12) !important;
}
