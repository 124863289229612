@import "src/styles/helpers";

.separateLanguageButton {
  height: 100%;
}

.separateLanguageModal {
  &__content {
    height: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
