@import "src/styles/helpers";

.button {
  display: block;
  border-radius: 8px;
  text-align: center;
  padding: 12px 18px;
  transition: all 0.3s ease;
  width: fit-content;
  font-family: Inter, sans-serif;
  @include buttonFont;
  white-space: nowrap;

  &:disabled,
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.primary {
    background: var(--accent-800);
    color: var(--white);

    &:hover {
      background: var(--accent-500);
    }

    &:active {
      background: var(--accent-900);
    }
  }

  &.secondary {
    background: var(--secondary-200);
    color: var(--primary-600);

    &:hover {
      background: var(--secondary-300);
      color: var(--accent-900);
    }

    &:active {
      background: var(--secondary-300);
    }

    &:disabled,
    &.disabled {
      background: var(--secondary-100);
      color: var(--primary-100);
    }
  }

  &.danger {
    background: var(--red-300);
    color: var(--white);

    &:hover {
      background: var(--red-200);
    }

    &:active {
      background: var(--red-400);
    }

    &:disabled,
    &.disabled {
      background: var(--red-100);
    }
  }

  &.outlined {
    border: 1px solid var(--primary-400);
    background: transparent;
    padding: 11px 17px;
    color: var(--primary-600);

    &:hover {
      border-color: var(--accent-700);
      color: var(--accent-700);
    }

    &:active {
      border-color: var(--primary-300);
      color: var(--primary-500);
    }

    &:disabled,
    &.disabled {
      border-color: var(--primary-100);
      color: var(--primary-100);
      pointer-events: none;
    }

    &_icon {
      border-color: var(--secondary-500);
    }
  }

  &.icon {
    background: transparent;
    padding: 11px 17px;
    color: var(--primary-600);

    &:hover {
      color: var(--accent-700);
    }

    &:active {
      color: var(--primary-500);
    }

    &:disabled,
    &.disabled {
      color: var(--primary-100);
      pointer-events: none;
    }
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: transform 0.3s ease;
      margin-left: 8px;
      flex-shrink: 0;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      @keyframes spin {
        to {
          transform: rotate(1turn);
        }
      }

      .spinner {
        width: 16px;
        height: 16px;
        animation: 1s spin linear infinite;
      }
    }

    &_left {
      flex-direction: row-reverse;

      .icon {
        margin-right: 8px;
        margin-left: 0;
      }
    }

    &_center {
      padding: 11px;

      .icon {
        margin: 0;
      }
    }
  }

  &.small {
    padding: 10px 12px;
    @include bodyExtraSmall;

    &.withIcon {
      .icon {
        margin-left: 4px;
      }

      &_left {
        flex-direction: row-reverse;

        .icon {
          margin-right: 4px;
          margin-left: 0;
        }
      }

      &_center {
        padding: 10px;

        .icon {
          margin: 0;
        }
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }
}
