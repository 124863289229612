@import "src/styles/helpers";

$basePercent: 56%;

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

.loader {
  position: relative;
  border-radius: 50%;
  background: conic-gradient(transparent, #6D89AA 70%, #4C6A8E);
  mask-image: radial-gradient(circle, transparent $basePercent, black $basePercent);
  -webkit-mask-image: radial-gradient(circle, transparent $basePercent, black $basePercent);
  animation: spinner 1s infinite linear;
}
